// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  baseUrl: 'https://api.dev.slik.mt/api/v1',//api.dev.slik.mt
  per_page : 25,
  mapUrl:"https://maps.google.com/maps/api/js?key=AIzaSyCDvOZTRv0cAPm3-_Jq2OA8J_0R6wEG_-s",
  ws_url: 'https://api.zenith.dev.codeblack.mt/broadcasting/auth'
};